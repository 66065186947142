﻿body {
    .image-placeholder {
        height: 300px;
    }

    .back-button.gray-pencil {
        background: #555;
    }

    .lesson-tile {
        text-transform: uppercase;
        text-align: center;
        height: 300px;
        overflow: hidden;
        position: relative;
        color: #07536F;
        display: inline-block;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        &.lesson-for-teacher {
            background-color: #f1f1f2 !important;

            &:nth-child(odd) {
                background-color: #ccc !important;
            }


            &:hover {
                .imgactivity {
                    background-color: #FFB812 !important;
                }
            }
        }

        h3, h4 {
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 0;
            position: absolute;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }

        .imgcha {
            width: 100%;
            display: inline-block;
        }

        h4 {
            padding-top: 40px;
            padding-left: 10px;
            margin-bottom: 25px;
            padding-right: 10px;
            top: 60px;
        }

        .tile-content {
            z-index: 2;
            position: absolute;
            width: 90%;
            height: 100%;
        }

        &:hover {
            -webkit-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            transition: all 0.3s ease;
            //background: none;
            h4 {
                color: inherit;

                &.grade {
                    color: #FFF;
                }
            }

            .imgactivity {
                opacity: 1;
                z-index: 1;
            }
        }

        .imgactivity {
            background: #e6e6e6;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            z-index: -1;
        }

        .blue {
            color: #ffb812;

            &:hover {
                background: #07536F;
            }
        }

        h4.grade {
            padding-top: 20px;
            color: #555;
            top: 0px;
        }


        .btn-download {
            width: 150px;
            margin: 0 auto;
            position: absolute;
            bottom: 30px;
            color: #FFB812;
            background: #313130;
            font-size: 20px;
            font-weight: 700;
            border-radius: 0;
            min-width: 175px;
            text-transform: uppercase;
            white-space: normal;
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
        }
    }


    .lessons-col {
        background: #D0D0CE;
        color: #555;
        padding-top: 1px;
    }


    .lesson-heading {
        background: #07536F;
        color: #fff;
        text-align: center;
        padding: 80px 100px;
    }

    .lesson-heading h2 {
        font-size: 48px;
        font-weight: 700;
    }

    .lesson-heading {
        font-size: 20px;
        font-weight: 700;
    }

    .view-lesson {
        background: #555;
        text-align: center;
        padding: 10px;
        //border-top: rgba(234, 234, 237, 0.2) solid 1px;
    }

    .view-lesson > p {
        font-size: 36px;
        font-weight: 700;
        color: #fff;
    }

    .view-lesson span {
        display: inline-block;
        font-size: 120px;
        padding: 10px;
        background: #FFB812;
        font-weight: 700;
        margin-bottom: 30px;
        width: 70%;
        color: #555;
    }

    .lesson-col.blue .view-lesson span {
        line-height: 130px;
    }

    .view-lesson span > p {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 30px;
    }

    .lesson-col {
        background: #fff;
        text-transform: uppercase;

        &.normal-text {
            text-transform: none;
        }

        &:nth-child(odd) {
            background: #D0D0CE;
        }
    }


    .lesson-col.gray {
        background: #D0D0CE;
    }

    .lesson-col.white {
        background: #fff;
    }

    .lesson-col.blue, .activity-box.blue {
        background: #07536F;
    }

    .lesson-col .click-lesson {
        text-align: center;
        color: #555;
        padding: 10px;
        padding-top: 90px;
    }

    .teacher-box .lesson-col .click-lesson {
        padding-top: 40px;
    }

    #teacher-description h3 {
        padding-bottom: 120px;
        padding-top: 120px;
        text-align: center;
        color: #fff;
    }

    .click-lesson h3, .blue-lesson h3, #teacher-description h3 {
        font-weight: 700;
        font-size: 48px;
        margin: 0px;
    }

    .teacher-box .lesson-col .click-lesson h3 {
        margin-bottom: 30px;
    }

    .blue-lesson {
        padding-top: 130px;
    }

    .blue-lesson h3 {
        color: #fff;
        text-align: center;
    }

    .click-lesson .start {
        color: #000;
        font-size: 24px;
        font-weight: 700;
        margin-top: 20px;
    }

    .click-lesson .hidden-sm, .click-lesson .chapter {
        font-size: 24px;
        font-weight: 700;
    }

    .click-lesson .btn-view {
        margin: 0;
    }

    .lesson-description {
        background: #D0D0CE;
        color: #555;
        padding: 30px 40px 20px 40px;
    }

    .lesson-description p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 20px;
    }

    .big-btn {
        text-transform: uppercase;
        text-align: center;
        display: block;
        font-size: 24px;
        font-weight: 700;
        padding: 30px 10px;
        -webkit-transition: .3s linear 0s;
        transition: .3s linear 0s;
    }

    .big-btn > div {
        width: 100%;
        display: inline-block;
        max-width: 200px;
        padding: 10px 5px 0 5px;
        min-height: 210px;
    }

    .big-btn > div img {
        display: inline-block;
    }

    .big-btn > div span {
        display: block;
        margin-top: 20px;
    }

    .big-btn:hover {
        text-decoration: none;
    }

    .yellow-btn {
        background: #FFB812;
        color: #805E25;
    }

    .yellow-btn:hover {
        color: #805E25;
        box-shadow: inset 0px 0px 0px 10px #FFD17D;
    }

    .yellow-btn > div {
        background: #FFD17D;
    }

    .blue-btn {
        background: #08546F;
        color: #6D8299;
    }

    .blue-btn:hover {
        color: #6D8299;
        box-shadow: inset 0px 0px 0px 10px #6D8299;
    }

    .blue-btn > div {
        background: #6D8299;
    }

    .blue-btn > div span {
        color: #08546F;
    }

    .gray-btn {
        background: #97999A;
        color: #BCBBBC;
    }

    .gray-btn:hover {
        color: #BCBBBC;
        box-shadow: inset 0px 0px 0px 10px #D0D1CF;
    }

    .gray-btn > div {
        background: #D0D1CF;
    }

    .gray-btn > div span {
        color: #97999A;
    }

    .lesson-description .listing li {
        list-style: none;
        color: #555;
        position: relative;
        margin-bottom: 40px;
    }

    .lesson-description .listing li p {
        margin-bottom: 0;
    }

    .lesson-description .listing li a {
        word-break: break-all;
        display: block;
        font-size: 18px;
        line-height: 30px;
        color: #555;
        text-decoration: underline;
    }

    .lesson-description .listing li:before {
        content: "\e074";
        font-family: 'Glyphicons Halflings';
        color: #555;
        position: absolute;
        left: -40px;
        top: 0px;
        font-size: 20px;
    }

    .lesson-height {
        height: 300px;
    }

    .yellow-inculude {
        background: #FFB812;
        color: #fff;
        text-align: center;
        line-height: 350px;
    }

    .yellow-inculude span {
        font-size: 36px;
        font-weight: 700;
        line-height: 36px;
        display: inline-block;
    }

    @media (min-width:768px) and (max-width:1024px) {
        .lesson-heading {
            padding: 30px 90px;
        }

        .click-lesson h3 {
            font-size: 40px;
        }
    }

    @media (max-width:767px) {
        .lesson-col .click-lesson {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .blue-lesson {
            padding: 50px 0;
        }

        .lesson-heading {
            padding: 30px 20px;
        }

        .click-lesson h3, .contact-thank h4 {
            font-size: 40px;
        }

        .click-lesson {
            padding-bottom: 50px;
        }
    }
}
