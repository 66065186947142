﻿body {

    .contact {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    .contact-description {
        padding: 35px;

        h3 { 
            padding-bottom: 120px;
            padding-top: 120px;
            text-align: center;
            color: #fff;
        }
    }

    .btn-submit {
        background: #FFB812;
        color: #555;
        font-size: 24px;
        padding: 6px 25px;
        border-radius: 0;
        font-weight: 700;
        margin-right: 10px;
    }

    .contact-form {
        .form-control {
            border-radius: 0;
        }
    }

    .contact-thank {
        color: #fff;
        text-align: center;
        padding: 40px 10px;

        h4 {
            font-size: 48px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        p {
            font-size: 18px;
            margin-bottom: 40px;
        }
    }
}
