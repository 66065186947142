﻿
#login {
    .modal-body {
        border-radius: 0;
        background: #555;
        border: 4px solid #000;
        background-size: cover;

        .form-col {
            padding: 30px 30px 0px 30px;

            .m-b-40 {
                margin-bottom: 40px;
            }

            .login-error {
                opacity: 0;
                color: red;
                padding: 10px;
                background-color: #000;
                transition: all .3s ease-in-out;

                &.active {
                    &:before {
                        content: "";
                    }

                    opacity: 1;
                    transition: all .3s ease-in-out;
                }
            }

            .form-control {
                border-radius: 0;
                border: 4px #000 solid;
                font-size: 22px;
                height: auto;
                font-weight: 700;
            }

            .btn-submit {
                background: #FFB812;
                color: #555;
                font-size: 24px;
                padding: 6px 25px;
                border-radius: 0;
                font-weight: 700;
                margin-right: 10px;
            }
        }
    }
}
