﻿body {
    .modal-open {
        .modal.in {
            display: flex !important;
            display: block ;
            height: 100%;
            align-items: center;

            .modal-dialog {
                margin: auto;
            }
        }
    }


    .modal {
        opacity: 0;

        &.in {
        }
    }
}
