﻿body.energyschool-body {

    .add-space-span {
        margin-right: 18px;
        text-align: left;
        display: inline-block;
    }

    header {
        background: #414140;
        position: fixed;
        width: 100%;
        z-index: 5;
        padding: 25px 0 17px 0;

        .logo {
            padding: 0;
            width: auto;
            float: left;
        }

        .login-view {
            text-align: right;
            color: #EAEAED;
            margin-top: 14px;

            form {
                display: inline;
            }
        }


        .btn-login {
            background: #FFB812;
            color: #555;
            padding: 2px 30px;
            border-radius: 0;
            font-weight: 700;
            margin-right: 10px;
        }
    }
    /* for print file  */
    .header {
        padding: 20px 0;
    }

    .header h1 {
        margin: 0;
        font-size: 30px;
        font-weight: 700;
    }
}
