﻿

body.energyschool-body {

    footer {
        background: #414140;
        color: #eaeaed;

        &.sticky {
            position: fixed;
            width: 100%;
            bottom: 0px;
        }

        p {
            padding-top: 15px;
        }

        .nav {
            li {
                a {
                    color: #EAEAED;

                    &:after {
                        background: #EAEAED;
                        width: 1px;
                        content: "";
                        height: 20px;
                        position: absolute;
                        right: 0;
                    }
                }


                &:last-child a:after {
                    width: 0px;
                }

                a:hover, a:focus, a:active {
                    background: #414140;
                    color: #EAEAED;
                }
            }
        }
    }

    .footer {
        margin-top: 50px;
        font-size: 16px;

        .text-rgt {
            text-align: right;
            font-weight: 700;
        }
    }




    @media (max-width:767px) {

        footer .nav li {
            display: inline-block;

            a {
                color: #EAEAED;
                display: inline-block;
            }
        }
    }
}
