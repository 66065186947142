// -----------------------------------------------------------------------------
// Foundation Sans Fonts
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../energyschool/fonts/HelveticaNeueLTStd-Roman.eot');
    src: url('../../energyschool/fonts/HelveticaNeueLTStd-Roman.eot?#iefix') format('embedded-opentype'), 
        url('../../energyschool/fonts/HelveticaNeueLTStd-Roman.woff') format('woff'), 
        url('../../energyschool/fonts/HelveticaNeueLTStd-Roman.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../energyschool/fonts/HelveticaNeueLTStd-Bd.eot');
    src: url('../../energyschool/fonts/HelveticaNeueLTStd-Bd.eot?#iefix') format('embedded-opentype'), 
        url('../../energyschool/fonts/HelveticaNeueLTStd-Bd.woff') format('woff'), 
        url('../../energyschool/fonts/HelveticaNeueLTStd-Bd.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

/*@font-face { 
    font-family: 'Glyphicons Halflings'; 
    src: url("../../energyschool/fonts/glyphicons-halflings-regular.eot"); 
    src: url("../../energyschool/fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), 
         url("../../energyschool/fonts/glyphicons-halflings-regular.woff2") format("woff2"), 
         url("../../energyschool/fonts/glyphicons-halflings-regular.woff") format("woff"), 
         url("../../energyschool/fonts/glyphicons-halflings-regular.ttf") format("truetype"), 
         url("../../energyschool/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg"); 
}*/

