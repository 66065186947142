﻿.external-form {
    .header {
        background-color: #005a82;
        padding: 10px !important;
        color: #ffffff;
        font-weight: bold;
        font-size: 15pt;
    }

    .sections {
        background-color: #b3cee1;
        padding: 10px;
        font-weight: bold;
        font-size: 13pt;
        margin-bottom: 10px;
    }

    label {
        top: 18px;
    }

    input[type="checkbox"] {
        margin: 25px 5px 0px 10px;
    }

    .btn-submit {
        background-color: #005a82;
        color: #ffffff;
        border-radius: 0px;
        padding: 10px 25px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11pt;
    }

    input[type="submit"] {
        float: none;
        display: block;
        margin: 0 auto;
        margin-top: 20px;
    }

    .error {
        color: #ff0000;
        font-size: 10pt;
        margin-top: 5px;
    }

    input.error {
        border: 1px solid #ff0000;
    }
}

.contact-form-container {
    padding-left: 35px;
    padding-right: 35px;

    .itm-inputtext {
        &.inputtextarea {
            height: 150px;
        }

        display: block;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        color: #555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
        -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
        transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }

    label {
        width: 100%;
    }

    input, textarea {
        width: 100%;
    }

    .header {
        background-color: #005a82;
        padding: 10px !important;
        color: #ffffff;
        font-weight: bold;
        font-size: 15pt;
    }

    .sections {
        background-color: #b3cee1;
        padding: 10px;
        font-weight: bold;
        font-size: 13pt;
        margin-bottom: 10px;
    }

    label {
        top: 18px;
    }

    input[type="checkbox"] {
        margin: 25px 5px 0px 10px;
    }

    .btn-submit {
        background-color: #005a82;
        color: #ffffff;
        border-radius: 0px;
        padding: 10px 25px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11pt;
    }

    input[type="submit"] {
        float: none;
        display: block;
        margin: 0 auto;
        margin-top: 20px;
    }

    .error {
        color: #ff0000;
        font-size: 10pt;
        margin-top: 5px;
    }

    input.error {
        border: 1px solid #ff0000;
    }
}
