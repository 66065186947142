﻿$dark-gray: #434343;
$light-gray: gray;
$white: #FFF;
$black: #OOO;
$yellow: #FFB812;
$enb-blue: #08546F;

.dashboard-page {
    .dashboard-banner {
        height: 300px;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: gray;

        .dashboard-heading {
            padding: 20px;
            color: white;

            h3 {
                font-weight: bold;
            }
        }
    }

    .dashboard-chart {
        height: 600px;
    }

    .admin-dashboard {
        padding: 20px;
        text-align: center;
    }

    @media (max-width:767px) {
        .dashboard-tile {
            padding: 30px;

            .large-score {

                .score-container {
                    margin: 0px !important;
                    height: auto !important;
                }
            }

            .tile-score {
                padding: 0px;
            }
        }
    }


    .dashboard-tile {
        height: 300px;
        padding: 20px;
        overflow: hidden;
        font-weight: bold;
        text-align: center;

        h2, h4 {
            font-weight: bold;
            text-align: center;
        }

        &.activity-tile {
            margin: 0 auto;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            h4 {
                font-size: 20px;
                font-weight: 700;
                color: $enb-blue;
                text-align: center;
                height: 90px;
            }

            &:hover {
                .activity-overlay {
                    opacity: 1;
                    transition: all ease-in-out .2s;
                }
            }

            .activity-overlay {
                padding: 30px;
                opacity: 0;
                transition: all ease-in-out .2s;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;

                h4 {
                    color: $white;
                }
            }
        }

        .dashboard-score {
            position: relative;
            width: 100%;
            height: 100%;

            &.large-score {

                .score-container {
                    margin: 40px;
                    height: 160px;
                    position: relative;
                    background-color: red;
                    background: $dark-gray;
                }

                .tile-score {
                    width: 48%;
                    padding: 20px;
                    background: $dark-gray;
                    margin: 10px;
                    position: absolute;
                    text-align: center;

                    &.left {
                        left: 0;
                    }

                    &.right {
                        border-left: 2px solid white;
                        right: 0;
                    }
                }

                .tile-number {
                    color: #FFF;
                    font-size: 100px;
                    line-height: 80px;

                    &.yellow {
                        color: #FFB812;
                    }
                }

                .tile-heading {
                    color: #FFF;
                    padding-top: 5px;

                    &.yellow {
                        color: #FFB812;
                    }
                }
            }

            &.small-score {
                color: $white;

                .score-container {
                    margin: 10px;
                    position: relative;
                    height: 120px;
                    background: #434343;
                }

                .tile-score {
                    width: 45%;
                    padding: 20px;
                    background: #434343;
                    margin: 10px;
                    position: absolute;
                    text-align: center;
                    bottom: 0px;
                    height: 100px;

                    &.left {
                        left: 0;
                    }

                    &.right {
                        border-left: 2px solid white;
                        right: 0;
                    }
                }

                .tile-number {
                    color: #FFF;
                    font-size: 50px;
                    line-height: 40px;
                    font-weight: bold;

                    &.yellow {
                        color: #FFB812;
                    }
                }

                .tile-heading {
                    color: #FFF;
                    line-height: 14px;
                    text-align: center;
                    padding-top: 5px;

                    &.yellow {
                        color: #FFB812;
                    }
                }
            }
        }


        .icon {
            width: 120px;
            margin: 0 25px;
        }

        &.blue {
            background-color: $enb-blue;
        }

        &.gray {
            background-color: #97999A;
        }
    }

    select {
        width: 100%;
        padding: 10px;
    }



    .content-description {
        .content-info {
            padding: 10px 0px;
        }
    }
}
