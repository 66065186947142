﻿body.energyschool-body {
    /* activity */
    .activity-col {
        background: #555;
        text-align: center;
        padding: 80px 10px;

        h4 {
            font-size: 36px;
            font-weight: 700;
            margin: 0;
            color: #fff;
        }
    }

    .back-button {

        a {
            color: white;
            text-decoration: none;
        }
    }

    .point-img img {
        display: inline-block;
    }

    .activity-banner {
        background-color: #97999B;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .yellowblack-btn {
        background: #FFB812;
        color: #555;
        padding-top: 50px;
        height: 300px;
    }

    .yellowblack-btn:hover {
        color: #555;
    }

    .bluewhite-btn {
        background: #07536F;
        color: #EAEAED;
        padding: 10px;
        height: 300px;
    }

    .bluewhite-btn:hover {
        color: #EAEAED;
    }

    .bluewhite-btn .main-div, .big-btn .main-div {
        display: table;
        width: 100%;
        max-width: 100%;
        padding: 0;
        height: 100%;
    }

    .big-btn .main-div {
        height: 210px;
    }

    .bluewhite-btn.big-btn .main-div {
        height: 100%;
    }

    .bluewhite-btn .main-div .info, .big-btn .main-div .info {
        vertical-align: middle;
        display: table-cell;
    }

    .activity-box {
        text-align: center;
        height: 300px;
        overflow: hidden;
        position: relative;
        color: #07536F;
        -webkit-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease;
        width: 300px;
        display: inline-block;

        h3, h4 {
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 0;
        }

        .imgcha {
            width: 100%;
            display: inline-block;
        }

        &:hover {
            -webkit-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            transition: all 0.3s ease;

            .imgactivity {
                opacity: 1;

                img {
                    -webkit-filter: grayscale(0%);
                    -moz-filter: grayscale(0%);
                    -ms-filter: grayscale(0%);
                    color-interpolation-filters: grayscale(0%);
                    filter: none;
                }
            }
        }
        
        .tile-content {
            z-index: 2;
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .imgactivity {
            background: #e6e6e6;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            z-index: 1;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;

            img {
                -webkit-filter: grayscale(100%) brightness(170%) opacity(.4);
                filter: grayscale(100%) brightness(170%) opacity(.4);
            }
        }

        .blue {
            color: #ffb812;

            &:hover {
                background: #07536F;
            }
        }
    }


    .activity-box:hover {
        background: none;
        color: #fff;

        .imgactivity {
            background: #ffb812;
        }
    }


    .imgactivity img {
        width: 100%;
        min-height: 300px;
    }

    .activity-box:hover .imgactivity img {
        opacity: 1;
    }

    .activity-box h4 {
        padding-top: 20px;
        padding-left: 10px;
        margin-bottom: 15px;
        padding-right: 10px;
    }

    .activity-box:hover .point-hover {
        display: none;
    }

    .activity-box .point-hover {
        position: absolute;
        left: 50%;
        margin-left: -44px;
        top: 145px;
    }

    .activity-box .btn-hover {
        display: none;
    }

    .activity-box:hover .btn-hover {
        display: inline-block;
        margin-top: 50px;
    }

    .activity-box:hover h4 {
        padding-top: 60px;
    }
}
