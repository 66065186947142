﻿

body.energyschool-body {

    .info-heading {
        .jumbotron {
            border-radius: 0;
            background-repeat:no-repeat;
            background-position:left top;
            background-size: cover;
            margin: 0;
            text-align: left;
            padding-top: 20px;
            padding-left: 20px;

            h1 {
                font-weight: 700;
                margin: 0;
                font-size: 40px;
                width: 50%;
                padding-bottom: 230px;
            }
        }
    }


    .info-view {
        margin-left: -30px;
        text-align: center;
        padding: 5px 45px;

        h2 {
            font-family: Bebas Neue;
            font-size: 30px;
            color: #FFB812;
            font-weight: 700;
        }

        img {
            display: inline-block;
        }
    }



    .info-description {
        background: #97999B;
        padding: 30px 40px 20px 40px;

        p {
            color: #fff;
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 20px;
        }

        h2 {
            font-size: 24px;
            font-weight: 700;
            color: #fff;
            margin-top: 0;
            margin-bottom: 30px;
        }
    }







    .bluewhite-btn .main-div .info, .big-btn .main-div .info {
        vertical-align: middle;
        display: table-cell;
    }



    @media (min-width:990px) and (max-width:1200px) {
        .info-heading .jumbotron h1 {
            font-size: 30px;
            padding-bottom: 185px;
        }
    }

    @media (min-width:768px) and (max-width:1024px) {


        .info-heading {
            .jumbotron {
                background-size: 150%;

                h1 {
                    font-size: 30px;
                    padding-bottom: 160px;
                    width: 60%;
                }
            }
        }
    }

    @media (max-width:767px) {

        .info-heading {
            .jumbotron {
                background-size: cover;
                background-position: 50% 0%;

                h1 {
                    font-size: 40px;
                    padding-bottom: 350px;
                    width: 100%;
                }
            }
        }



        .info-view {
            margin-left: 0;
        }
    }
}
