@import "bootstrap/bootstrap.min";

@import "base/fonts";                                                                                                                                                       

@import "Partials/info";
@import "Partials/menu";
@import "Partials/modal";
@import "Partials/login";
@import "Partials/activity";
@import "Partials/lesson";
@import "Partials/footer";
@import "Partials/header";
@import "Partials/content";
@import "Partials/contact";
@import "Partials/legacy";
@import "Partials/forms";
@import "Partials/dashboard";
