﻿body {
    font-family: 'Helvetica Neue LT Std';
    background: #EAEAED;

    .main-info {
        padding-top: 110px;
        padding-bottom: 80px;
    }

    .contact {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    #learn-description {
        margin-left: -30px;
        background: #D0D0CE;
        padding: 50px 30px;
        text-align: center;
    }

    #learn-description h3 {
        margin-top: 0;
        color: #555;
        font-size: 35px;
        font-weight: 700;
        line-height: 45px;
    }

    #learn-img {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    #teacher-img img {
        width: 100%;
    }

    .btn-application {
        background: #414140;
        color: #fff;
        font-size: 24px;
        border-radius: 0;
        padding: 10px 30px;
        margin-top: 30px;
    }

    .btn-application:hover {
        color: #ccc;
    }

    #challenge-img {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-left: -30px;
    }

    #challenge-description {
        background: #FFB812;
        text-align: center;
        padding: 30px 35px;
    }

    #challenge-description h3 {
        font-size: 35px;
        color: #fff;
        line-height: 45px;
    }

    #challenge-description .btn-link {
        padding: 0;
        color: #007DBA;
        font-size: 22px;
    }

    #challenge-description p {
        font-size: 22px;
        color: #000;
    }

    .btn-submit {
        background: #FFB812;
        color: #555;
        font-size: 24px;
        padding: 6px 25px;
        border-radius: 0;
        font-weight: 700;
        margin-right: 10px;
    }

    .tearm-view {
        font-size: 11px;
        text-align: center;
        display: block;
        margin-top: 64px;
        color: #fff;
    }

    .tearm-view:hover {
        color: #ccc;
    }


    .activities-col {
        background: #FFB812;
        color: #fff;
        padding-top: 1px;
    }

    .tips-col {
        background: #07536F;
        color: #fff;
        padding-top: 1px;
    }

    .lessons-col {
        background: #D0D0CE;
        color: #555;
        padding-top: 1px;
    }

    .services {
        text-align: center;
    }

    .services h3 {
        font-size: 44px;
        font-weight: 700;
        margin: 60px 0;
    }

    .btn-view {
        color: #FFB812;
        background: #313130;
        font-size: 20px;
        font-weight: 700;
        border-radius: 0;
        min-width: 175px;
        margin-bottom: 60px;
        text-transform: uppercase;
        white-space: normal;
    }

    .btn-view:hover {
        color: #FFB812;
    }

    .btn-procced {
        color: #414140;
        background: #FFB812;
        font-size: 20px;
        font-weight: 700;
        border-radius: 0;
        min-width: 175px;
        margin-bottom: 60px;
        text-transform: uppercase;
        white-space: normal;
    }

    .btn-procced:hover {
        color: #414140;
    }

    .btn-black {
        color: #EAEAED;
        background: #414140;
        font-size: 20px;
        font-weight: 700;
        border-radius: 0;
        white-space: normal;
        display: inline-flex;
        justify-content: center;
        /* align horizontal */
        align-items: center;
        padding: 5px 30px;
        min-height: 45px;
        line-height: 20px;
    }

    .btn-black:hover, .btn-black:focus {
        color: #EAEAED;
    }

    .btn-white {
        color: #414140;
        background: #EAEAED;
        font-size: 20px;
        font-weight: 700;
        border-radius: 0;
        white-space: normal;
        padding: 10px 35px;
        margin-top: 14px;
    }

    .btn-white:hover {
        color: #414140;
    }

    .btn-view.btn-hover:hover {
        color: #414140;
        background: #FFB812;
    }

    .btn-procced.btn-hover:hover {
        color: #FFB812;
        background: #313130;
    }

    .lucid-col {
        background: #fff;
        text-align: center;
        padding-top: 30px;
    }

    .teacher-box .lesson-col .click-lesson {
        padding-top: 40px;
    }

    #teacher-description h3 {
        padding-bottom: 120px;
        padding-top: 120px;
        text-align: center;
        color: #fff;
    }

    #teacher-description {

        &.double-header {
            h3 {
                margin: 0 auto;
                line-height: 35px;

                span {
                    font-size: 30px;
                }
            }
        }
    }

    .click-lesson h3, .blue-lesson h3, #teacher-description h3 {
        font-weight: 700;
        font-size: 48px;
        margin: 0px;
    }

    .teacher-box .lesson-col .click-lesson h3 {
        margin-bottom: 30px;
    }

    .blue-lesson {
        padding-top: 130px;
    }

    .blue-lesson h3 {
        color: #fff;
        text-align: center;
    }

    .click-lesson .start {
        color: #000;
        font-size: 24px;
        font-weight: 700;
        margin-top: 20px;
    }

    .click-lesson .hidden-sm, .click-lesson .chapter {
        font-size: 24px;
        font-weight: 700;
    }

    .click-lesson .btn-view {
        margin: 0;
    }

    .lesson-description {
        background: #D0D0CE;
        color: #555;
        padding: 30px 40px 20px 40px;

        &.left-align {
            text-align: left !important;
        }
    }

    .lesson-description p {
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 20px;
    }

    .big-btn {
        text-align: center;
        display: block;
        font-size: 24px;
        font-weight: 700;
        padding: 30px 10px;
        -webkit-transition: .3s linear 0s;
        transition: .3s linear 0s;
    }

    .big-btn > div {
        width: 100%;
        display: inline-block;
        max-width: 200px;
        padding: 10px 5px 0 5px;
        min-height: 210px;
    }

    .big-btn > div img {
        display: inline-block;
    }

    .big-btn > div span {
        display: block;
        margin-top: 20px;
    }

    .big-btn:hover {
        text-decoration: none;

        p {
            text-decoration: none;
        }
    }

    .yellow-btn {
        background: #FFB812;
        color: #805E25;
    }

    .yellow-btn:hover {
        color: #805E25;
        box-shadow: inset 0px 0px 0px 10px #FFD17D;
    }

    .yellow-btn > div {
        background: #FFD17D;
    }

    .blue-btn {
        background: #08546F;
        color: #6D8299;
    }

    .blue-btn:hover {
        color: #6D8299;
        box-shadow: inset 0px 0px 0px 10px #6D8299;
    }

    .blue-btn > div {
        background: #6D8299;
    }

    .blue-btn > div span {
        color: #08546F;
    }

    .gray-btn {
        background: #97999A;
        color: #BCBBBC;
    }

    .gray-btn:hover {
        color: #BCBBBC;
        box-shadow: inset 0px 0px 0px 10px #D0D1CF;
    }

    .gray-btn > div {
        background: #D0D1CF;
    }

    .gray-btn > div span {
        color: #97999A;
    }

    .lesson-description {
        .listing {
            li {
                list-style: none;
                color: #555;
                position: relative;
                margin-bottom: 40px;

                p {
                    margin-bottom: 0;
                }

                a {
                    word-break: break-all;
                    display: block;
                    font-size: 18px;
                    line-height: 30px;
                    color: #555;
                    text-decoration: underline;
                }

                &:before {
                    content: "\e074";
                    font-family: 'Glyphicons Halflings';
                    color: #555;
                    position: absolute;
                    left: -40px;
                    top: 0px;
                    font-size: 20px;
                }
            }
        }
    }





    .lesson-height {
        height: 300px;
    }

    .yellow-inculude {
        background: #FFB812;
        color: #fff;
        text-align: center;
        line-height: 350px;

        span {
            font-size: 36px;
            font-weight: 700;
            line-height: 36px;
            display: inline-block;
        }
    }

    .contact-form {
        .form-control {
            border-radius: 0;
        }
    }

    .contact-thank {
        color: #fff;
        text-align: center;
        padding: 40px 10px;

        h4 {
            font-size: 48px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        p {
            font-size: 18px;
            margin-bottom: 40px;
        }
    }




    .no-paddingR {
        padding-right: 0;
    }

    .no-paddingL {
        padding-left: 0;
    }

    .education-page {
        width: 900px;
    }

    .image-placeholder {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .gray-box {
        height: 300px;
        text-align: center;
        background: #555;
        color: #fff;
        padding: 30px 100px;

        &.blue-bg {
            background: #08546F;
            color: #FFF;
            padding: 30px 60px;

            h2 {
                font-weight: bold;
            }

            p {
                font-size: 15px;
                font-weight: 700;
                margin: 20px auto;
            }
        }

        p {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 20px;
        }

        .btn {
            margin-bottom: 0;
        }
    }



    .tips-box {
        height: 300px;
        width: 300px;
        text-align: center;
        overflow: hidden;
        position: relative;
        padding: 15px;

        &:nth-child(even) {
            background: #97999B;
        }

        &:nth-child(odd) {
            background: #ACADAF;
        }

        h4 {
            color: #fff;
            font-size: 36px;
            font-weight: 700;
            margin-top: 50px;
            margin-bottom: 60px;
        }

        .circle-text {
            position: absolute;
            bottom: 30px;
            left: 50%;
            margin-left: -37.5px;
        }
    }



    .circle-text {
        color: #ffb812;
        background: #555;
        display: inline-block;
        border-radius: 50%;
        font-size: 44px;
        font-weight: 700;
        width: 75px;
        height: 75px;
        line-height: 75px;
        margin: 0;
    }

    .tips-hover {
        //background-size: cover;
        height: 0px;
        overflow: hidden;
        margin-left: -15px;
        margin-right: -15px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        -webkit-transition: height 0.3s;
        transition: height 0.3s;

        .btn {
            margin-top: 130px;
        }
    }


    .tips-box:hover .tips-hover {
        height: 300px;
        height: 300px;
        background-repeat: no-repeat;
        background-position: center;
        //  background-size: cover;
    }

    .tips-top {
        position: relative;
    }

    .house-box {
        position: absolute;
        bottom: 10px;
        left: 10px;
    }

    .tips-bottom {
        background: #D0D0CE;

        .tips-heading {
            background: #555555;
            text-align: center;

            h4 {
                font-size: 24px;
                font-weight: 700;
                color: #eaeaed;
                margin-bottom: 50px;
            }

            .circle-text {
                background: #97999B;
                color: #eaeaed;
                margin-top: 20px;
            }
        }
    }

    .tips-heading {
        height: 200px;
    }

    .tips-description {
        line-height: 170px;
        padding: 20px 0px 0 10px;
        height: 200px;

        span {
            line-height: 24px;
            color: #555;
            display: inline-block;
        }
    }


    .tips-modal {
        .modal {
            background: rgba(255, 255, 255, 0.3);
        }

        .modal-content {
            border-radius: 0;
        }

        .modal-body {
            padding: 0;
        }

        .modal-dialog {
            min-width: 700px;
        }

        .modal-close {
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 50px;
            background: #555;
            opacity: 1;

            span {
                position: relative;
                display: block;

                &:before {
                    content: "";
                    position: absolute;
                    width: 35px;
                    height: 6px;
                    background: #D0D0CE;
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                    top: 20px;
                    left: 6px;
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: 35px;
                    height: 6px;
                    background: #D0D0CE;
                    -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    top: 20px;
                    left: 6px;
                }
            }
        }
    }


    .tips-modal .modal-close:hover span:before {
        background: #ffb812;
    }

    .tips-modal .modal-close:hover span:after {
        background: #ffb812;
    }

    .lesson-box .lesson-col .click-lesson h3 {
        margin-bottom: 30px;
    }

    &.modal-open {
        .modal.in {
            display: flex !important;
            height: 100%;

            .modal-dialog {
                margin: auto;
            }
        }
    }

    .disply300 {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: 1240px;
    }

    .width600 {
        width: 600px;
    }
    /* for print file  */
    .header {
        padding: 20px 0;

        h1 {
            margin: 0;
            font-size: 30px;
            font-weight: 700;
        }
    }

    .logo-print {
        text-align: right;
    }

    .content-col {
        font-size: 18px;
    }

    .table-col {
        border: 0;
        background: #fff;
        width: 99.8%;

        table {
            margin-bottom: 0;
        }

        thead tr th {
            background: #005b84;
            color: #fff;
            font-size: 20px;
            border: 1px solid #555;
        }

        tbody tr th {
            font-size: 20px;
            background: #b6d0e1;
            border: 1px solid #555;
        }

        tbody tr td {
            border: 1px solid #555;
        }
    }

    .table-col thead tr th.borderL0, .table-col tbody tr th.borderL0, .table-col tbody tr td.borderL0 {
        border-left: 0;
    }

    .table-col thead tr th.borderR0, .table-col tbody tr th.borderR0, .table-col tbody tr td.borderR0 {
        border-right: 0;
    }

    .table-col tbody tr td.input-table {
        padding: 0;
    }

    .table-col tbody tr td.input-table input[type="text"], .input-table input[type="text"] {
        background: #d8f0ff;
        outline: 0;
        border: 0;
        display: block;
        width: 100%;
        font-size: 20px;
        padding: 6px 12px;
        height: 40px;
    }

    .table-col tbody tr td.input-table input[type="text"]:focus, .input-table input[type="text"]:focus {
        background: #fff;
    }

    .no-wrap {
        white-space: nowrap;
    }

    .custom-checkbox {
        display: none;
    }

    .custom-checkbox + label {
        padding-left: 20px;
        display: inline-block;
        position: relative;
        margin-left: 5px;
        font-weight: normal;
    }

    .custom-checkbox + label:before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        left: 0;
        top: 2px;
        border: 1px solid #ccc;
        background: #f1f4ff;
    }

    .custom-checkbox + label:after {
        content: "";
        position: absolute;
        left: 3px;
        top: 5px;
        width: 10px;
        height: 6px;
        border: 2px solid transparent;
        transform: rotate(0);
        transition: transform 0.3s;
    }

    .custom-checkbox:checked + label:after {
        border-left-color: #000;
        border-bottom-color: #000;
        transform: rotate(-45deg);
    }

    .additional-data label {
        font-weight: normal;
    }

    .yellow-color {
        color: #FFB812;
    }

    ol.list-abc, ol.list-roman {
        counter-reset: list;
    }

    ol.list-abc > li, ol.list-roman > li {
        list-style: none;
        position: relative;
    }

    ol.list-abc > li:before {
        counter-increment: list;
        content: "("counter(list, lower-alpha) ") ";
        position: absolute;
        left: -40px;
    }

    ol.list-roman > li:before {
        counter-increment: list;
        content: "("counter(list, lower-roman) ") ";
        position: absolute;
        left: -30px;
        width: 25px;
        text-align: center;
    }

    .list-none {
        list-style: none;
    }

    .black-line {
        border: 5px #000 solid;
    }

    .gray-pencil {
        background: #4D4D4F;
        color: #fff;
        padding: 10px;
        font-size: 16px;
    }

    .yellow-pencil {
        background: #FDB71A;
        color: #231F20;
        padding: 10px;
        font-size: 16px;
        min-height: 225px;
    }

    .pencil-col {
        margin-bottom: 20px;

        .img-responsive {
            margin: 15px 0;
        }
    }

    .website-link {
        background: #005B84;
        color: #fff;
        text-align: center;
        padding: 20px;
        display: block;
        margin-bottom: 20px;

        &:hover {
            color: #fff;
        }
    }



    .btn-back {
        color: #fff;
        display: inline-block;
        padding: 5px;
        padding-left: 40px;
        font-size: 15px;
        font-weight: 700;
        background: url(../images/back-gray.png) no-repeat left center;
        display: none;

        &:hover {
            color: #FFB812;
            background: url(../images/back-yellow.png) no-repeat left center;
            text-decoration: none;
        }
    }

    .btn-backset {
        color: #fff;
        display: inline-block;
        padding: 10px;
        padding-left: 40px;
        font-size: 15px;
        font-weight: 700;
        background: url(../images/back-gray.png) no-repeat left center;
        margin-top: 36px;
        display: inline-block;
    }

    html.ios .btn-view, html.ios .btn-procced, html.ios .btn-white {
        padding: 10px 12px 4px 12px;
    }

    html.ios .circle-text {
        line-height: 85px;
    }

    html.ios .view-lesson span {
        padding-top: 40px;
    }

    @media (min-width:990px) and (max-width:1200px) {

        #learn-description h3 {
            font-size: 24px;
        }

        .btn-application {
            word-wrap: normal;
        }

        .disply300 {
            width: 940px;
        }
    }

    @media (min-width:768px) and (max-width:1024px) {
        .add-space-span {
            //max-width: 140px;
        }

        .btn-back {
            margin-top: 20px;
            margin-left: 22px;
            margin-bottom: 0;
        }

        .main-info {
            padding-top: 150px;
        }

        #learn-description {
            padding: 25px 15px;
        }

        #learn-description h3 {
            font-size: 24px;
        }

        .btn-application {
            word-wrap: normal;
        }

        #challenge-description {
            padding: 30px;
        }

        .services h3 {
            font-size: 30px;
        }

        .lesson-heading {
            padding: 30px 90px;
        }

        .click-lesson h3 {
            font-size: 40px;
        }

        .disply300 {
            width: 640px;
        }
    }

    @media (max-width:767px) {
        .btn-back {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .main-info {
            //padding-top: 180px;
        }


        .width600.gray-box {
            width: 300px;
            padding: 30px;
            height: auto;
        }

        .disply300 {
            width: 340px;
        }

        .tips-discription {
            height: auto;
        }

        .lesson-col .click-lesson {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        #turncate {
            overflow: hidden;
            margin-bottom: 30px;
            height: 298px;
        }

        .tips-bottom .tips-heading {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .tips-modal .modal-dialog {
            min-width: inherit;
        }

        .no-paddingR {
            padding-right: 15px;
        }

        .no-paddingL {
            padding-left: 15px;
        }

        .activity-box.blue.col-sm-6.col-md-4.col-lg-3.col-xs-12.no-paddingR.no-paddingL {
            padding: 0;
        }

        #teacher-img {
            background-size: cover;
            margin-left: 0;
            margin-right: 0;
        }

        .blue-lesson {
            padding: 50px 0;
        }

        .lesson-heading {
            padding: 30px 20px;
        }

        .click-lesson h3, .contact-thank h4 {
            font-size: 40px;
        }

        .click-lesson {
            padding-bottom: 50px;
        }

        .login-view {
            /*margin-top: 45px;*/
            margin-top: 0;
            padding: 0;
        }

        .logo {
            padding-bottom: 10px;
            padding-top: 0;
        }

        .login-view .add-space-span {
            display: block;
            float: left;
            width: 160px;
            margin-right: 10px;
        }

        .btn-login {
            margin: 2px;
        }
        /*.info-view,*/
        #learn-description, #challenge-img {
            margin-left: 0;
        }

        #learn-img, #challenge-img {
            height: 300px !important;
            background-size: cover;
        }

        #learn-description h3 {
            font-size: 24px;
        }

        #learn-description {
            padding: 30px 10px;
        }

        #login .form-col {
            padding: 120px 10px 0px 10px;
        }
    }

    @media (min-width: 1530px) {
        .disply300 {
            width: 1540px;
        }
    }

    @media (min-width: 1200px) {
        .width1250.disply300 {
            width: 1240px;
        }
    }
}
