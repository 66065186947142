﻿body {

    .content-page {

        .content-description {
            iframe {
                border: none;

                .pc {
                }
            }
        }
    }
}
