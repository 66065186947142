﻿body {

    .toggle-menu {
        cursor: pointer;
        padding: 5px;
        width: 36px;
        height: 36px;
        background: #555555;
        float: left;

        .lines {
            width: 25px;
            height: 3px;
            background: #ecf0f1;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            position: relative;
            top: 11px;
            float: left;

            &:after, &:before {
                display: inline-block;
                width: 25px;
                height: 3px;
                background: #ecf0f1;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                position: absolute;
                left: 0;
                content: '';
                -webkit-transform-origin: center;
                transform-origin: center;
            }

            &:before {
                top: -6px;
            }

            &:after {
                top: 6px;
            }
        }

        &.toggle-open {
            .lines {
                background: transparent;

                &:after, &:before {
                    -webkit-transform-origin: 50% 50%;
                    transform-origin: 50% 50%;
                    left: 0px;
                    top: 0;
                    width: 24px;
                    height: 4px;
                }

                &:before {
                    -webkit-transform: rotate3d(0, 0, 1, -45deg);
                    transform: rotate3d(0, 0, 1, -45deg);
                }

                &:after {
                    -webkit-transform: rotate3d(0, 0, 1, 45deg);
                    transform: rotate3d(0, 0, 1, 45deg);
                }
            }
        }
    }



    .seprator {
        background: #555;
        width: 2px;
        height: 38px;
        margin: 0 10px;
        display: inline-block;
        position: relative;
        float: left;
    }

    .navigation-menu {
        left: 0;
        width: 300px;
        position: fixed;
        background-color: #414140;
        color: #fff;
        width: 300px;
        top: 82px;
        bottom: 0;
        border-top: 1px solid #fff;
        -webkit-font-smoothing: antialiased;
        -moz-font-smoothing: antialiased;
        -o-font-smoothing: antialiased;
        -webkit-transition-duration: 300ms;
        transition-duration: 300ms;
        -webkit-transform: translate3d(-300px, 0, 0);
        transform: translate3d(-300px, 0, 0);
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;
        z-index: 10;

        @media (max-width:992px) {
            top: 81px;
        }

        ul.menu {
            margin: 0;
            padding: 0;
            overflow-y: auto;
            max-height: 70vh;

            li {
                list-style: none;

                a {
                    padding: 10px;
                    padding-left: 40px;
                    display: block;
                    font-size: 20px;
                    color: #555;
                    background: #EAEAED;
                    font-weight: 700;
                }

                span {
                    padding: 10px;
                    padding-left: 40px;
                    display: block;
                    font-size: 20px;
                    color: #fff;
                    font-weight: 700;
                }

                &.active a {
                    color: #555;
                    background: #fff;
                }
            }
        }

        .simple-menu li {
            list-style: none;
        }
    }



    .navigation-menu ul.menu li a:hover, .navigation-menu ul.menu li a:focus, .navigation-menu ul.menu li.active a:hover {
        color: #555;
        background: #FFB812;
        text-decoration: none;
    }

    .navigation-menu .simple-menu {
        position: absolute;
        bottom: 20px;
        padding: 0;
        margin: 0;
        width: 100%;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }

    .navigation-menu .simple-menu li a {
        color: #EAEAED;
        display: block;
        padding: 5px;
        text-align: center;
    }

    .navigation-menu .simple-menu li a:hover {
        color: #FFB812;
        text-decoration: none;
    }

    .toggle-open .navigation-menu {
        -webkit-transition-duration: 300ms;
        transition-duration: 300ms;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;
        box-shadow: 0 15px 15px #222;
    }
}
